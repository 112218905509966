<template>
  <BaseDialog
    size-class="!max-w-xl"
    :model-value="show"
    @update:model-value="
      searchExchange = '';
      searchPairs = '';
      close();
    "
  >
    <template #header>
      <div class="flex w-full justify-between">
        <div :class="mobileView ? 'text-xs' : 'text-sm'">
          Pairs Selection <span v-if="showManageExchange"> -> Manage My Exchanges </span>
        </div>
      </div>
    </template>
    <template #default>
      <div class="items-center overflow-y-hidden text-center">
        <BaseSearchbox v-if="showManageExchange" v-model="searchExchange" placeholder="Search exchanges" />
        <BaseSearchbox v-else v-model="searchPairs" placeholder="Search pairs & exchanges" />
        <div :class="mobileView ? 'py-1' : 'py-4'" class="flex items-center justify-between" v-if="!showManageExchange">
          <div class="flex">
            <BaseRadio v-model="selectedTab" :options="['My Exchanges', 'All Exchanges']" />
          </div>
          <div class="p-2 pr-4">
            <Cog8ToothIcon
              @click="showManageExchange = true"
              class="h-4 w-4 cursor-pointer text-gray-400 hover:text-white"
            />
          </div>
        </div>
        <div :style="{ height: `${maxTableHeight}px` }" class="w-full">
          <template v-if="showManageExchange">
            <div class="h-full space-y-4 overflow-y-auto">
              <div class="w-full">
                <div class="py-3 text-left text-xs font-semibold">My Exchanges</div>
                <div
                  class="overflow-y-scroll rounded border border-grid bg-gray-800"
                  :class="mobileView ? 'h-32' : 'h-40'"
                >
                  <div v-if="filteredMyExchanges.length">
                    <div
                      v-for="item in filteredMyExchanges"
                      :key="item"
                      class="flex cursor-pointer items-center justify-between px-4 hover:bg-gray-700"
                      @click="addRemoveExchange('remove', item)"
                    >
                      <div class="flex items-center space-x-2 py-2 text-xs">
                        <img class="h-5 w-5 rounded-full object-cover" :src="item.logo_url" />
                        <span class="block">{{ item.name }}</span>
                      </div>
                      <div class="cursor-pointer text-red-400">-</div>
                    </div>
                  </div>
                  <NoDataFound v-else class="mt-16" />
                </div>
              </div>
              <div class="w-full">
                <div class="pb-3 text-left text-xs font-semibold">Available Exchanges</div>
                <div
                  class="overflow-y-scroll rounded border border-grid bg-gray-800"
                  :class="mobileView ? 'h-32' : 'h-40'"
                >
                  <div
                    v-for="item in filteredAllExchanges"
                    :key="item"
                    class="flex cursor-pointer items-center justify-between px-4 hover:bg-gray-700"
                    @click="addRemoveExchange('add', item)"
                  >
                    <div class="flex items-center space-x-2 py-2 text-xs">
                      <img class="h-5 w-5 rounded-full object-cover" :src="item.logo_url" />
                      <span class="block">{{ item.name }}</span>
                    </div>
                    <div class="cursor-pointer text-green-400">+</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <BaseTable
              class="h-full"
              :row-height="40"
              :row-model-type="'infinite'"
              :row-selection="favoritesModal ? 'multiple' : 'single'"
              :row-multi-select-with-click="favoritesModal ? true : false"
              :column-defs="columnDefs"
              @grid-ready="onGridReady"
              @selection-changed="onSelectionChanged"
              @row-selected="onRowSelected"
            />
          </template>
        </div>
      </div>
    </template>
    <template #footer>
      <BaseButton v-if="showManageExchange" class="mt-5" type="secondary" size="sm" @press="hideManageExchange">
        <ArrowLeftIcon class="mr-1 h-4 w-4" /> Back
      </BaseButton>
      <div v-else-if="favoritesModal" class="flex w-full justify-end space-x-2">
        <BaseButton type="secondary" size="sm" @press="close()">Cancel</BaseButton>
        <BaseButton size="sm" @press="emit('save', selectedPairs), close()">Save</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script setup>
import { CcxtApi } from '@/api/ccxtApi';
import { ref, onMounted, computed, watch } from 'vue';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import { formatNumber } from '@/composeables/filters';
import { ArrowLeftIcon } from '@heroicons/vue/20/solid';
import { Cog8ToothIcon } from '@heroicons/vue/24/outline';
import NoDataFound from '@/components/NoDataFound.vue';
import AgGridImageCell from '@/components/ag_grid/AgGridImageCell.vue';
import AgGridExchangePairCell from '@/components/ag_grid/AgGridExchangePairCell.vue';
import { numberComparator } from '@/composeables/ag_grid/comparators';
import { useStore } from 'vuex';

const emit = defineEmits(['close', 'apply:pair', 'save']);
const $ccxt = new CcxtApi();
const $store = useStore();

const props = defineProps({
  show: { type: Boolean, default: false },
  exchanges: { type: Array, default: () => [] },
  pairs: { type: Array, default: () => [] },
  allExchanges: { type: Array, default: () => [] },
  uid: { type: String, default: '' },
  favoritesModal: { type: Boolean, default: false },
  mobileView: { type: Boolean, default: false }
});

//TABS
const selectedTab = ref('My Exchanges');
watch(selectedTab, () => {
  reloadTable();
});

//GRID
const gridApi = ref(null);
const columnApi = ref(null);
const selectedPairsId = ref([]);
function onGridReady(params) {
  gridApi.value = params.api;
  columnApi.value = params.columnApi;
  reloadTable();
}
function reloadTable() {
  rowCount.value = 0;
  gridApi.value.setDatasource(dataSource);
  columnApi.value?.autoSizeAllColumns();
}
function onRowSelected(params) {
  if (params.node?.selected) {
    if (!selectedPairsId.value.includes(params.data.id)) {
      selectedPairs.value.push(modifyPairs([params.data])[0]);
      selectedPairsId.value.push(params.data.id);
    }
  } else {
    selectedPairs.value = selectedPairs.value.filter(x => x.id != params.data.id);
    selectedPairsId.value = selectedPairsId.value.filter(x => x != params.data.id);
  }
}
function onSelectionChanged() {
  const selectedRows = gridApi.value.getSelectedRows();
  emit('apply:pair', selectedRows[0]);
}

//TABLE CONFIG
const columnDefs = computed(() => {
  let columns = [];
  if (props.favoritesModal) {
    columns.push(
      {
        headerName: props.mobileView ? '' : 'Select',
        checkboxSelection: true,
        cellStyle: { display: 'flex', 'align-items': 'center' },
        maxWidth: props.mobileView ? 30 : 100
      },
      {
        headerName: 'Pair',
        cellRenderer: AgGridExchangePairCell,
        field: 'pair',
        cellStyle: { display: 'flex', 'align-items': 'center' },
        sortable: true,
        width: 300,
        maxWidth: props.mobileView ? 150 : null
      },
      {
        headerName: 'Exchange',
        field: 'logo_url',
        cellRenderer: AgGridImageCell,
        cellStyle: { display: 'flex', 'align-items': 'center' },
        cellRendererParams: params => {
          return {
            description: params.data?.name || params.data?.exchange,
            imageClasses: 'w-4 h-4 rounded object-cover',
            styleClasses: 'w-full'
          };
        },
        tooltipField: 'name',
        sortable: true
      }
    );
  } else {
    columns.push(
      {
        headerName: 'Exchange',
        field: 'logo_url',
        cellRenderer: AgGridImageCell,
        cellStyle: { display: 'flex', 'align-items': 'center' },
        cellRendererParams: params => {
          return {
            description: params.data?.name || params.data?.exchange,
            imageClasses: 'w-4 h-4 rounded object-cover'
          };
        },
        sortable: true
      },
      { headerName: 'Pair', field: 'pair', cellStyle: { display: 'flex', 'align-items': 'center' }, sortable: true }
    );
  }
  columns.push(
    {
      headerName: props.mobileView ? 'Base Vol.' : 'Base Volume',
      field: 'day_volume',
      type: 'rightAligned',
      valueFormatter: params => {
        return params.data?.day_volume && params.data?.day_volume != 0.0
          ? formatNumber(params.data.day_volume, 2)
          : '-';
      },
      comparator: numberComparator,
      cellStyle: { display: 'flex', 'align-items': 'center', 'justify-content': 'flex-end' },
      sortable: true,
      width: 188,
      maxWidth: props.mobileView ? 80 : null
    },
    {
      headerName: props.mobileView ? 'Quote Vol.' : 'Quote Volume',
      field: 'quote_volume',
      type: 'rightAligned',
      valueFormatter: params => {
        return params.data?.quote_volume && params.data?.quote_volume != 0.0
          ? formatNumber(params.data.quote_volume, 2)
          : '-';
      },
      maxWidth: props.mobileView ? 90 : null,
      comparator: numberComparator,
      cellStyle: { display: 'flex', 'align-items': 'center', 'justify-content': 'flex-end' },
      sortable: true
    }
  );
  return columns;
});

//TABLE DATA
const allCoins = computed(() => {
  return $store.getters.coins;
});
const searchPairs = ref('');
const rowCount = ref(0);
const selectedPairs = ref([]);
const dataSource = {
  rowCount: undefined,
  getRows: async params => {
    gridApi.value.showLoadingOverlay();
    let sortModel = params.sortModel[0] ? params.sortModel[0]['colId'] : 'day_volume';
    if (sortModel == 'logo_url') sortModel = 'exchange';
    let getExchangePairsFlag =
      selectedTab.value == 'My Exchanges' && myExchanges.value.map(i => i.exchange)?.length == 0 ? false : true;

    const response = getExchangePairsFlag
      ? await $ccxt.getExchangePairs({
          coin_uid: props.uid,
          page: params.endRow / 100,
          per_page: 100,
          search: searchPairs.value?.split(' ')?.[0],
          exchange: searchPairs.value?.split(' ')?.[1],
          my_exchanges: selectedTab.value == 'All Exchanges' ? '' : myExchanges.value.map(i => i.exchange),
          sort_column: sortModel,
          sort_order: params.sortModel[0] ? params.sortModel[0]['sort'] : 'desc'
        })
      : { data: { data: [] } };

    const pairs = modifyPairs(response.data.data);
    rowCount.value += pairs.length;

    params.successCallback(pairs, pairs.length < 100 ? rowCount.value : null);

    gridApi.value.hideOverlay();
    columnApi.value?.autoSizeAllColumns();

    if (pairs == 0 && params.startRow == 0) gridApi.value.showNoRowsOverlay();
    if (props.favoritesModal) {
      gridApi.value.forEachNode(node => {
        if (selectedPairsId.value?.includes(node?.data?.id)) {
          node.setSelected(true);
        }
      });
    }
  }
};
function modifyPairs(pairs) {
  const exchanges = groupBy(props.allExchanges, 'exchange');
  return pairs.map(x => {
    const exchange = exchanges[x.exchange]?.[0] || null;
    let pairsData = {
      ...x,
      logo_url: exchange?.logo_url || null,
      name: `${exchange?.name} (${exchange?.exchange})`
    };
    return props.favoritesModal
      ? {
          ...pairsData,
          base_symbol_uid:
            allCoins.value.find(c => c.coin.ticker.toLowerCase() == x.base_symbol.toLowerCase())?.coin_uid || '',
          quote_symbol_uid:
            allCoins.value.find(c => c.coin.ticker.toLowerCase() == x.quote_symbol.toLowerCase())?.coin_uid || ''
        }
      : pairsData;
  });
}
function close() {
  emit('close');
  selectedPairsId.value = [];
  selectedPairs.value = [];
  showManageExchange.value = false;
}
async function getExchangePairs() {
  const response = await $ccxt.getExchangePairs({
    ids: selectedPairsId.value
  });
  selectedPairs.value = modifyPairs([...response.data.data]);
  if (gridApi.value) reloadTable();
}

watch(
  searchPairs,
  debounce(() => {
    reloadTable();
  }, 200)
);
watch([() => props.pairs, () => props.show], () => {
  if (props.show) {
    selectedPairsId.value = props.pairs;
    if (selectedPairsId.value.length > 0) getExchangePairs();
  }
});

//MY EXCHANGES
const myExchanges = ref(props.exchanges || []);
watch(
  () => props.exchanges,
  exchanges => {
    if (exchanges) {
      myExchanges.value = exchanges;
    }
  }
);

//MANAGE EXCHANGES
const searchExchange = ref('');
const showManageExchange = ref(false);
const filteredMyExchanges = computed(() => {
  return myExchanges.value.filter(
    i => i.exchange && i.exchange.toLowerCase().match(searchExchange.value.toLowerCase())
  );
});
const filteredAllExchanges = computed(() => {
  return props.allExchanges
    .filter(i => !myExchanges.value.map(j => j.exchange).includes(i.exchange))
    .filter(i => i.exchange && i.exchange.toLowerCase().match(searchExchange.value.toLowerCase()));
});
function hideManageExchange() {
  showManageExchange.value = false;
  reloadTable();
}
async function addRemoveExchange(type, item) {
  if (type == 'add') {
    var index = myExchanges.value.findIndex(x => x.exchange == item.exchange);
    if (index != -1) return;
    myExchanges.value.push(item);
  } else {
    myExchanges.value = myExchanges.value.filter(i => i.exchange !== item.exchange);
  }
  const res = await $ccxt.updateExchange({
    exchange: myExchanges.value.map(i => i.exchange).join(',')
  });
}

//CONTAINER RESIZE
const maxTableHeight = ref(0);
onMounted(() => {
  if (ResizeObserver) {
    new ResizeObserver(() => {
      setMaxTableHeight();
    }).observe(document.body);
  }
});
function setMaxTableHeight() {
  //35% of the Total Screen Height
  maxTableHeight.value = screen.height * 0.35;
}
</script>
